<script setup lang="ts">
import type { SoloCTA } from '~/utils/cta'

const props = withDefaults(
  defineProps<SoloCTA>(),
  { readable: false, theme: 'light', ctaContent: null }
)
const link = computed(() => props.cta[props.cta.linkType])
</script>

<template>
  <div :class="['solo-cta pb-8', themeStyles(theme)]">
    <div
      :class="[
        'max-wrapper flex',
        { readable },
        {'justify-start': position === 'left'},
        {'justify-center': position === 'center'},
        {'justify-end': position === 'right'}
      ]"
    >
      <BlockContent v-if="ctaContent" :content="ctaContent" />
      <POButton
        v-if="props.cta.linkType"
        :id="props.cta.gaTag"
        class="mt-2"
        :btn-style="props.cta.style"
        :color="props.cta.color"
        :uppercase="true"
        :link="link"
      >
        {{ props.cta.text }}
      </POButton>
    </div>
  </div>
</template>
